<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="租户名称："
        prop="tenantId"
        v-if="$store.state.tenantId === '000000'"
      >
        <el-select
          v-model="ruleForm.tenantId"
          filterable
          remote
          placeholder="请选择租户"
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="(item, index) in options"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="ruleForm.roleName"></el-input>
      </el-form-item>
      <el-form-item label="角色描述" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark"></el-input>
      </el-form-item>
      <el-form-item label="启用" prop="isAvailable">
        <el-radio-group v-model="ruleForm.isAvailable">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { listTenant, roleSubmit } from "@/api/SystemData";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
        } else if (!v) {
          this.ruleForm = {
            isAvailable: true,
            tenantId: "",
            roleName: "",
            remark: "",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        isAvailable: true,
        tenantId: "",
        roleName: "",
        remark: "",
      },
      rules: {
        tenantId: [
          { required: true, message: "请选择租户", trigger: "change" },
        ],
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "change" },
          {
            min: 0,
            max: 32,
            message: "长度不能超过32个字符",
            trigger: "change",
          },
        ],
        remark: [
          {
            min: 0,
            max: 128,
            message: "长度不能超过128个字符",
            trigger: "change",
          },
        ],
      },
      loading: true,
      options: [],
    };
  },
  async created() {
    // this.loading = true;
    // let list = await this.initList();
    // this.loading = false;
    if (this.$store.state.tenantId === "000000") {
      this.options = JSON.parse(JSON.stringify(this.$store.state.tenantList));
      this.options.splice(0, 1);
    }
    // else {
    //   this.options = [];
    // }
  },
  methods: {
    initList(query = "") {
      return new Promise((resolve, reject) => {
        listTenant({
          tenantName: query,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {};
          if (this.$store.state.tenantId !== "000000") {
            obj.tenantId = this.$store.state.tenantId;
          }
          roleSubmit({ ...this.ruleForm, ...obj }).then((res) => {
            this.handleClose();
            this.$emit("changeList");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          isAvailable: true,
          tenantId: "",
          roleName: "",
          remark: "",
        };
      }
    },
    async remoteMethod(query) {
      this.loading = true;
      let list = await this.initList(query);
      this.loading = false;
      if (list) {
        this.options = list;
      } else {
        this.options = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
</style>
