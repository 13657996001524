<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="operateTitle='添加', editData={},isAddVisible = true"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="RoleManagement"
      >
      <!-- <template slot="operate-left">
        <el-button
          type="success"
          size="small"
          @click="handleGoPath('/SystemManagement/SetPermissions')"
        >设置权限</el-button>
      </template>-->
      <template slot="isAvailable"
        slot-scope="{item}">
        <el-switch v-model="item.isAvailable"
          disabled></el-switch>
      </template>
      <template slot="grantAccountCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline"
          @click="roleId = item.id,isByUserVisible = true">{{ item.grantAccountCount }}</el-button>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          type="text"
          size="small"
          v-if="handleHasPerms('M22-role-edit')"
          @click="handleAssociation(item)">关联用户</el-button>
        <el-button class="polecy-button"
          type="text"
          v-if="handleHasPerms('M22-role-edit')"
          size="small"
          @click="handleGoPath('/SystemManagement/SetPermissions', item)">设置权限</el-button>
        <el-button class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M22-role-edit')"
          type="text"
          size="small">编辑</el-button>
        <el-button class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M22-role-edit')"
          type="text"
          size="small">删除</el-button>
      </template>
    </GeneralTable>
    <roleAddDialog :dialogVisible.sync="isAddVisible"
      :title="operateTitle"
      :editData="editData"
      @changeList="changeList"></roleAddDialog>
    <roleByUserDialog :dialogVisible.sync="isByUserVisible"
      :roleId="roleId" />
    <!-- 关联用户弹框 -->
    <AssociatingUsers :treeDialogVisible.sync="showTreeDialog"
      :treeData="relateTree"
      :treeSelectKeyList="treeSelectKeyList"
      :defaultProps="treeProps"
      :showOldSelectNode="true"
      :topPickerData="topPickerData"
      @submitData="submitRelateUser"
      @submitVacancyData="submitRelateUser([])"></AssociatingUsers>
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import roleAddDialog from "@/views/SystemManagement/components/role/roleAddDialog.vue";
import roleByUserDialog from "@/views/SystemManagement/components/role/roleByUserDialog.vue";
import AssociatingUsers from "@/components/SystemManagement/DialogBox/AssociatingUsers";
import { roleList, roleRemove, getRelateTreeData, roleBindUser, listGrantedUser, roleExport } from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  data() {
    return {
      option: {
        isAdd: hasPerms('M22-role-add'), //添加 事件 @add-change
        isDel: hasPerms('M22-role-edit'), //删除 @del-change
        isEdit: hasPerms('M22-role-edit'), // 编辑 @edit-change
        isExport: hasPerms("M22-role-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [{
          label: '租户名称',
          prop: 'tenantId',
          formType: 'select',
          clearable: true,
          filterable: true,
          isPermissions: this.$store.state.tenantId === '000000',
          placeholder: "请输入租户名称",
          selectList: this.$store.state.tenantList
        }, {
          label: '数据权限',
          prop: 'scopeType',
          formType: 'select',
          filterable: true,
          isPermissions: this.$store.state.tenantId !== '000000',
          // propValue: 1,
          placeholder: "请选择数据权限",
          selectList: [
            { value: '', text: '所有' },
            { value: 1, text: '全部' },
            { value: 2, text: '本人' },
            { value: 3, text: '本部门' },
            { value: 4, text: '本部门及下属' },
            { value: 5, text: '自定义' },
          ]
        },
        {
          label: '角色名称',
          prop: 'roleName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入角色名称"
        }, {
          label: '是否启用',
          prop: 'isAvailable',
          formType: 'select',
          filterable: true,
          placeholder: "请选择类型",
          selectList: [
            { value: '', text: '全部' },
            { value: true, text: '是' },
            { value: false, text: '否' },
          ]
        }, {
          label: '创建时间',
          formType: 'daterange',
          clearable: true,
            format: 'yyyy.MM.dd',
          prop: ['createTimeBegin', 'createTimeEnd']
        }]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "租户名称",
          prop: "tenantName",
          isPermissions: this.$store.state.tenantId === '000000',
          align: 'center',
          isShow: true,
        },
        {
          label: "角色名称",
          prop: "roleName",
          align: 'center',
          isShow: true,
        },
        {
          label: "数据权限",
          prop: "dataScope",
          align: 'center',
          isShow: true,
        },
        {
          label: "授权用户",
          prop: "grantAccountCount",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "角色描述",
          prop: "remark",
          align: 'center',
          isShow: true,
        },
        {
          label: "启用状态",
          prop: "isAvailable",
          align: 'center',
          isShow: true,
          isSlot: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 300,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: '添加',
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: '',
      showTreeDialog: false,
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      topPickerData: [],
      oldInterfaceData: {}
    };
  },
  components: { GeneralTable, roleAddDialog, roleByUserDialog, AssociatingUsers },
  created() { },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize) {
      if (this.$store.state.tenantId !== '000000') {
        v.tenantId = this.$store.state.tenantId;
      }
      this.oldInterfaceData = { ...v, current: pageNum, size: pageSize, likeRemark: v.keyword, likeRoleName: v.keyword, createTimeBegin: v.createTimeBegin ? v.createTimeBegin + ' 00:00:00' : '', createTimeEnd: v.createTimeEnd ? v.createTimeEnd + ' 23:59:59' : '' }
      const { code, data } = await roleList(this.oldInterfaceData);
      if (code === 200) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        const flag = e.findIndex(val => val.grantAccountCount > 0);
        if (flag > -1) {
          this.$message({
            type: 'warning',
            message: '请先移除该角色中的用户!'
          });
          return false;
        }
        this.$confirm('确认删除该角色?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id).toString();
          roleRemove({
            ids
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的角色！');
      }
    },
    // 编辑
    editItem(e) {
      this.editData = e;
      this.operateTitle = '编辑角色-' + e.roleName;
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path, item) {
      let ids = '';
      let name = '';
      let tenantId = '';
      if (item) {
        ids = item.id;
        name = item.roleName;
        tenantId = item.tenantId;
      } else if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
        ids = this.$refs.GeneralTable.tableSelectionList.map(val => val.id).toString();
        name = this.$refs.GeneralTable.tableSelectionList.map(val => val.roleName).toString();
      } else {
        this.$message.warning('请选择需要设置的角色！');
        return false;
      }
      this.$router.push({
        path: `${ path }/${ ids }/${ name }/${ tenantId }`,
      });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的角色！');
      //   return false;
      // }
      roleExport({
         ...this.oldInterfaceData,
        roleIds: e.map(val => val.id).toString()
      });
    },
    async handleAssociation(item) {
      this.editData = item;
      let userIds = await listGrantedUser({ roleId: item.id });
      let treeData = await getRelateTreeData({
        tenantId: item.tenantId
      });
      if (treeData.data && treeData.data.deptVOS) {
        this.relateTree = treeData.data.deptVOS;
      }
      if (userIds.data && userIds.code == 200) {
        this.treeSelectKeyList = userIds.data.map(val => {
          return {
            title: val.name,
            id: val.id
          };
        });
      }
      this.topPickerData = [{ label: item.roleName, value: 0 }];
      this.showTreeDialog = true;
      //   .then(res => {
      //   console.log(res);
      //   res.data && res.data.deptVOS ? this.relateTree = res.data.deptVOS : '';
      //   res.data && res.data.userIds ? this.treeSelectNodeList = res.data.userIds : '';
      // });
    },
    submitRelateUser(e) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      roleBindUser({
        roleId: this.editData.id,
        userIds: e.map(val => val.id)
      }).then(res => {
        this.changeList();
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    }

  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>