<template>
  <el-dialog
    title="授权用户"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <GeneralTable
      ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      v-loading="loading"
      tableName="roleByUserDialog"
    ></GeneralTable>
  </el-dialog>
</template>

<script>
import { pageByRoleId } from "@/api/SystemData";
import GeneralTable from "@/components/GeneralTable/index.vue";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    roleId: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    GeneralTable,
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v) {
          if (this.$refs.GeneralTable && this.$refs.GeneralTable.searchData) {
            this.$refs.GeneralTable.searchData.pageNum = 1;
            this.$refs.GeneralTable.searchData.pageSize = 10;
          }
          this.getList("", 1, 10);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
      option: {
        isOperate: false, //添加 事件 @add-change
        isSearch: false, // 搜索 @search-change
        isRefresh: false, // 刷新 @search-change
        isFirst: false,
        isSelection: false,
        pageSize: 10,
        rowPlaceholder: "--", // 列表数据为空时的显示
        searchList: [],
      },
      column: [
        {
          label: "登录账号",
          prop: "account",
          isShow: true,
          align: "center",
        },
        {
          label: "姓名",
          prop: "name",
          isShow: true,
          align: "center",
        },
        {
          label: "手机号",
          prop: "phone",
          isShow: true,
          align: "center",
        },
        {
          label: "部门",
          prop: "deptName",
          isShow: true,
          align: "center",
        },
        {
          label: "操作人",
          prop: "operator",
          isShow: true,
          align: "center",
        },
        {
          label: "操作时间",
          prop: "operatorTime",
          isShow: true,
          align: "center",
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
    };
  },
  async created() {},
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    getList(obj, current, size) {
      this.loading = true;
      pageByRoleId({
        current,
        size,
        roleId: this.roleId,
      })
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.claimRecordList = res.data.records;
            this.totalNum = res.data.total;
            console.log(this.claimRecordList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
::v-deep .container {
  padding: 0;
  .policy-table {
    margin-top: 0;
    padding: 0;
  }
}
</style>
